import { Box, ButtonBase, styled, alpha, Typography } from "@mui/material";
import { Link } from "@reach/router";
import dic from "~/data/dic";
import { useSelector } from "react-redux";
import UserIcon from "~/assets/icons/user.svg";

const Key = styled(ButtonBase, {
  shouldForwardProp: (props) => props !== "isTopMargin",
})(({ isTopMargin, theme }) => ({
  width: 44,
  height: 44,
  borderRadius: 4 * 4,
  ...(isTopMargin && { marginTop: 8 }),
  border: "3px solid var(--border-color)",
  color: "var(--primary-color)",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& img": {
    width: 26,
    height: 26,
  },
  "&:hover": {
    boxShadow: `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 0.2rem`,
    //borderColor: theme.palette.primary.main,
  },
}));

const MainMenu = ({ isHome }) => {
  const { userData } = useSelector(({ auth }) => auth);

  return (
    <Box
      sx={{
        position: "fixed",
        width: "inherit",
        display: "flex",
        ml: -10,
        flexDirection: "column",
      }}
    >
      {/* <Key>
        <img alt="menu" src="/icons/menu.svg" />
      </Key> */}
      {isHome && (
        <Link to="/">
          <Key isTopMargin>
            <img alt="home" src="/icons/home.svg" />
          </Key>
        </Link>
      )}
      <Link to="/profile">
        <Key isTopMargin>
          {!userData?.userId && (
            <Typography sx={{ fontWeight: "bold", mb: 0.5 }} component={"div"}>
              {dic.login}
            </Typography>
          )}
          {userData?.userId && <Box src={UserIcon} component={"img"} />}
        </Key>
      </Link>
      {/* <Key isTopMargin>
        <img alt="setting" src="/icons/setting.svg" />
      </Key> */}
    </Box>
  );
};

export default MainMenu;
