import { AppBar, Box, styled, Button } from "@mui/material";
import { Link } from "@reach/router";
import UserIcon from "~/assets/icons/user-light.svg";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import React from "react";

const Fr = styled(Box)({
  border: "2px solid white",
  height: 40,
  borderRadius: 4 * 3,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,
  "& img": {
    width: 24,
    height: 24,
  },
});

const Key = styled(Box)(({ theme }) => ({
  width: 40,
  flex: "0 0 40px",
  margin: theme.spacing(0, 1),
}));

//https://shop.monibapp.co.uk/

const MobileAppbar = ({ uk }) => {
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => () => {
    setState(open);
  };

  return (
    <>
      <SwipeableDrawer
        anchor={"bottom"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{}}
      >
        <Box sx={{ p: 2, py: 4 }}>
          <Button>کتاب‌ها</Button>
        </Box>
      </SwipeableDrawer>
      <AppBar
        sx={{
          bottom: 0,
          top: "auto",
          width: "calc(100% - 10px)",
          right: 5,
          borderRadius: (theme) => theme.spacing(2, 2, 0, 0),
          background: "url(/images/mobile-appbar-back.jpg)",
          color: "white",
          boxShadow: 10,
        }}
        position="fixed"
      >
        <Box
          sx={{
            height: 60,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 3.5,
          }}
        >
          <Link to={"/"}>
            <Key>
              <Fr>
                <Box component={"img"} src="/icons/home-light.svg" />
              </Fr>
            </Key>
          </Link>
          <Link to={"/user/playlist"}>
            <Key>
              <Fr>
                <Box component={"img"} src="/icons/myPlayList-light.svg" />
              </Fr>
            </Key>
          </Link>
          <Key disabled>
            <Fr>
              <Box component={"img"} src="/icons/menu-light.svg" />
            </Fr>
          </Key>
          <Link to={"/profile"}>
            <Key>
              <Fr>
                <Box component={"img"} src={UserIcon} />
              </Fr>
            </Key>
          </Link>
          <Link to={"/support"}>
            <Key>
              <Fr>
                <Box component={"img"} src={"/icons/support.svg"} />
              </Fr>
            </Key>
          </Link>
          {/* <Key disabled>
          <Fr>
            <Box component={"img"} src="/icons/menu-light.svg" />
          </Fr>
        </Key>
        <Key disabled>
          <Fr>
            <Box component={"img"} src="/icons/download-light.svg" />
          </Fr>
        </Key>
        <Key disabled>
          <Fr>
            <Box component={"img"} src="/icons/support-light.svg" />
          </Fr>
        </Key> */}
        </Box>
      </AppBar>
    </>
  );
};

export default MobileAppbar;
